import React from 'react'
import _ from 'lodash'

import TextGroup, { Image } from '../Texts'
import { Desktop, Mobile } from '../MediaQueries';

import logo1 from '../../assets/images/audit/ageas-big.svg'
import logo2 from '../../assets/images/audit/filinto.svg'
import logo3 from '../../assets/images/audit/kicks.svg'

import c_logo1 from '../../assets/images/audit/ericsson.svg'
import c_logo2 from '../../assets/images/audit/ageas.svg'
import c_logo3 from '../../assets/images/audit/worten.svg'
import c_logo4 from '../../assets/images/audit/pfizer.svg'
import c_logo5 from '../../assets/images/audit/nos.svg'
import c_logo6 from '../../assets/images/audit/sonae.svg'
import c_logo7 from '../../assets/images/audit/colombo.svg'
import c_logo8 from '../../assets/images/audit/lovys.svg'
import c_logo9 from '../../assets/images/audit/caravela.svg'
import c_logo10 from '../../assets/images/audit/filinto-1.svg'
import c_logo11 from '../../assets/images/audit/jeronimo.svg'
import c_logo12 from '../../assets/images/audit/shamir.svg'

const main_companies = [
    {
        image: logo1,
        text: 'A log é um parceiro extraordinário. Tivemos a oportunidade de trabalhar juntos na criação de um website para os nossos corretores, e não temos palavras para descrever a dedicação e profissionalismo da equipa. Se surgirem mais oportunidades no futuro, não hesitarei em trabalhar com a log.',
        person: 'Soraia Alves, Ageas',
    },
    {
        image: logo2,
        text: 'Escolhemos a log para o desenvolvimento do novo site do Grupo FILINTO MOTA, e não podíamos estar mais satisfeitos com o resultado final e com o desenrolar de todo o projecto. Desde então, a log assumiu-se como um parceiro fiável e de confiança, capaz de transformar todas as nossas ideias, da mais simples à mais complexa, em realidade.',
        person: 'Pedro Dias, Filinto Mota',
    },
    {
        image: logo3,
        text: 'A log ajudou-nos desde a primeira hora de desenvolvimento do nosso novo website. O trabalho foi aprofundado e começou pelo projeto de UX. Com uma pandemia pelo meio, os desafios na fase de implementação foram enormes, mas o método de trabalho com passos de 10 dias permitiu monitorizar o desenvolvimento do projeto e fazer ajustes em tempo real.',
        person: 'José Vieira, Kicks',
    },
]

const companies = [
  {
    image: c_logo1,
    title: 'Ericsson',
  },
  {
    image: c_logo2,
    title: 'Ageas',
  },
  {
    image: c_logo3,
    title: 'Worten',
  },
  {
    image: c_logo4,
    title: 'Pfizer',
  },
  {
    image: c_logo5,
    title: 'NOS',
  },
  {
    image: c_logo6,
    title: 'Sonae',
  },
  {
    image: c_logo7,
    title: 'Colombo',
  },
  {
    image: c_logo8,
    title: 'Lovys',
  },
  {
    image: c_logo9,
    title: 'Caravela',
  },
  {
    image: c_logo10,
    title: 'Filinto Mota',
  },
  {
    image: c_logo11,
    title: 'Jeronimo Martins',
  },
  {
    image: c_logo12,
    title: 'Shamir',
  },
]

const AuditThree = ({ lang }) => {
  const isEn = lang === 'en';
  return (
    <section id="audit-three" className="bg-white audit-three">
      <div className="container content">
        <TextGroup
          title={{
            type: 'h2',
            text: isEn ? 'Other clients.' : 'Clientes que confiam em nós',
          }}
        />
        <div className="company-images">
        {_.map(companies, (company, i) => (
            <React.Fragment key={`${company.title}-${i}`}>
              <Image {...company} />
            </React.Fragment>
          ))}
        </div>
      </div>

        <div className="container content main-companies">
            <TextGroup
            title={{
                type: 'h2',
                text: isEn ? 'Brands that trust us:' : 'Testemunhos:',
            }}
            />

            <div className="row">
                {_.map(main_companies, (company, i) => (
                    <div className="col-4 col-12-medium">
                        <React.Fragment>
                            <Image {...company} />
                            <p>{company.text}</p>
                            <span>{company.person}</span>
                        </React.Fragment>
                    </div>
                ))}
            </div>
        </div>
    </section>
  )
}
export default AuditThree
