import React, { useEffect, useState } from 'react';
import TextGroup, { CTA } from '../Texts'

import audit2 from '../../assets/images/audit/audit-2.png'
import ServiceModal from "../ServiceModal";
import close_icon from '../../assets/images/close.svg'

const AuditTwo = ({ lang }) => {
  const isEn = lang === 'en';
  const [clickedTileIndexes, setClickedTileIndexes] = useState([]);

  const [modalData, setModalData] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const handleTileClick = (index) => {

    setClickedTileIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((id) => id !== index);
      } else {
        return [...prevIndexes, index];
      }
    });

    //show modal
    setModalVisible(true);
    // set modal data on click
    setModalData(sliderTiles[index]);
  };
    

   // hide modal on close.
   const onModalCloseHandler = () => {
    setModalVisible(false);
    setClickedTileIndexes([]);
  };
    

  const sliderTiles = [
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? '1.\nO site não aparece nos resultados de pesquisa' : '1.\nO site não aparece nos resultados de pesquisa',
      },
      modal: {
        title:"O site não aparece nos resultados de pesquisa?",
        description: "Entre as causas mais comuns estão a indexação bloqueada, configurações incorretas no ficheiro robots.txt, estratégias de SEO inadequadas e falta de conteúdo otimizado. \n\n A log está pronta para fornecer soluções personalizadas e altamente profissionais que resolverão estes problemas, aumentando a visibilidade e o desempenho do seu site nos motores de pesquisa.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? '2.\nO site está lento e com dificuldade em carregar' : '2.\nO site está lento e com dificuldade em carregar',
      },
      modal: {
        title:"O site está lento e com dificuldade em carregar?",
        description: "Esses problemas podem surgir devido à necessidade de atualizações, ao excesso de plugins desnecessários, à escolha de um serviço de hospedagem de baixa qualidade ou à falta de otimização de imagens, entre outros fatores. \n\n Na log, somos especializados em resolver problemas de desempenho e velocidade em sites WordPress. Com a nossa ajuda, pode voltar a ter um site rápido, proporcionando uma experiência de navegação suave e eficiente ao utilizador.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? '3.\nO site desconfigura em dispositivos móveis' : '3.\nO site desconfigura em dispositivos móveis',
      },
      modal: {
        title:"O site desconfigura em dispositivos móveis?",
        description: "A desconfiguração em dispositivos móveis pode surgir devido à ausência de um design responsivo, erros de CSS, conflitos de plugins e configurações inadequadas.\n\nA log está aqui para oferecer soluções personalizadas de otimização do seu site WordPress. Garantimos que o seu site está totalmente compatível com dispositivos móveis e proporciona uma boa experiência de utilizador.",
      }
    },
    {
      rootProps: { className: 'tile' },
      title: {
        type: 'h3',
        text: isEn ? '4.\nO site precisa de traduções para outros idiomas' : '4.\nO site precisa de traduções para outros idiomas',
      },
      modal: {
        title:"O site precisa de traduções para outros idiomas?",
        description: "Se o seu site requer traduções para alcançar públicos em diferentes idiomas, é essencial utilizar um plugin de tradução confiável, além de otimizar metadados e URLs para cada idioma. Desta forma, garante uma experiência de utilizador consistente e relevante em todos os idiomas. \n\nNa log, estamos prontos para facilitar a implementação de traduções eficientes e otimizadas. Asseguramos que o seu site é acessível a uma audiência global de forma precisa e envolvente.",
      }
    },
    {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? '5.\nInstalei um novo plugin e o site deixou de funcionar' : '5.\nInstalei um novo plugin e o site deixou de funcionar',
        },
        modal: {
          title:"Instalei um novo plugin e o site deixou de funcionar?",
          description: "Se a instalação de um novo plugin causou uma falha no funcionamento do seu site, saiba que isso é um problema comum e pode ter várias causas. Entre as mais frequentes está a incompatibilidade do plugin com a versão do WordPress e PHP do seu site. Além disso, conflitos com outros plugins ou temas também podem ocorrer.\n\nA log dispõe de uma equipa de especialistas experientes em resolver bugs no WordPress e WooCommerce. Conte com a nossa ajuda!  ",
        }
       
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? '6.\nRecebo avisos de erro quando tento instalar um novo plugin ou tema' : '6.\nRecebo avisos de erro quando tento instalar um novo plugin ou tema',
        },
        modal: {
          title:"Recebo avisos de erro quando tento instalar um novo plugin ou tema?",
          description: "Saiba que essa situação é comum e pode ter diversas causas.Entre as causas mais frequentes estão a incompatibilidade de versão, conflitos de plugins, permissões de arquivo inadequadas, limite de memória do PHP atingido, entre outros.\n\nA log está à sua disposição para fornecer soluções personalizadas que resolvem esses problemas técnicos no seu site WordPress. A nossa equipa de especialistas tem uma vasta experiência em identificar e resolver bugs no WordPress e WooCommerce. ",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? '7.\nPerdi o conteúdo do site e não sei como recuperar' : '7.\nPerdi o conteúdo do site e não sei como recuperar',
        },
        modal: {
          title:"Perdi o conteúdo do site e não sei como recuperar?",
          description: "Se perdeu o conteúdo do seu site e não sabe como resolver a situação, a log está aqui para ajudar! Para além de exclusão acidental, isso pode ocorrer devido a problemas de backup, problemas de hospedagem, ataques de segurança, entre outros.\n\nA log está à sua disposição para solucionar de forma eficiente desafios relacionados ao seu site WordPress. Resolvemos desde exclusões acidentais até problemas de backup, alojamento, segurança, entre outras eventualidades imprevistas. ",
        }
      },
      {
        rootProps: { className: 'tile' },
        title: {
          type: 'h3',
          text: isEn ? '8.\nDetectei mensagens de erros incomuns' : '8.\nDetectei mensagens de erros incomuns',
        },
       
        modal: {
          title:"Detectei mensagens de erros incomuns?",
          description: "Mensagens de erro incomuns podem ser causadas por diversas razões que incluem: injeção de Malware, problemas de código, conflitos de plugins, atualizações mal sucedidas e problemas de servidor.\n\nA log dispõe de uma equipa especializada em cibersegurança dedicada a proteger o seu site WordPress. Explore as nossas soluções personalizadas e saiba como podemos resolver essas mensagens de erro de forma eficiente, garantindo sempre a segurança do seu site.",
        }
      },
  ]

  return (
    <section id="services-two" className="bg-blue services audit-two">
      <div className="content container">
        <div className="col-12 slider-wrapper">
          <TextGroup
            rootProps={{
              className: 'col-12 heading-large',
            }}
            title={{
              type: 'h2',
              text: isEn
                ? 'We work with you as one team.'
                : 'Que outros problemas enfrenta com o seu site WordPress?',
            }}
          />
          <div className="audit2-cnt">
            <div className="row tiles-row row-relative">
              {sliderTiles.map((tile, i) => (
                <div
                  key={`services-column-${i}`}
                  className={`col-4 col-12-medium tile-column ${
                    clickedTileIndexes.includes(i) ? 'clicked' : ''
                  }`}
                  onClick={() => handleTileClick(i)}
                >
                  <TextGroup {...tile} />
                </div>
              ))}
            </div>
            <div className="cta cta-audit">
              <CTA
                rootProps={{
                  className: 'button audit-btn audit-anchor-link gethelp-two',
                }}
                text={isEn ? "Let's talk" : 'Obtenha a nossa ajuda'}
              />
            </div>
            <img src={audit2} alt="Report" className="img-audit2" />
          </div>
        </div>
      </div>

      <ServiceModal
        visible={modalVisible}
        title={modalData?.modal?.title}
        description={modalData?.modal?.description}
      >
        <img 
          src={close_icon} 
          alt="Close" 
          className="service-modal__close"
          onClick={onModalCloseHandler}
        />
          <a 
            href="#" 
            className="button audit-btn audit-anchor-link gethelp-box"
            onClick={onModalCloseHandler}
          >
           {isEn ? "Schedule a Free Consultation" : 'Obtenha a nossa ajuda'}
          </a>
        </ServiceModal>
    </section>
  )
}

export default AuditTwo
