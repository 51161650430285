import React, { useEffect, useState } from 'react';
import _ from 'lodash'

import { AboutBlock } from '../Blocks'

import audit1 from '../../assets/images/audit/consultant2.png'

const AuditFour2 = ({ lang }) => {
  const isEn = lang === 'en';
  const [showMeetingContainer] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.async = true;
    document.body.appendChild(script);

    const gtmScript = document.createElement('script');
    gtmScript.type = 'text/javascript';
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WRFFKK57');

      window.addEventListener('message', function(event) {
        if (event.data.meetingBookSucceeded) {
          window.dataLayer.push({
            event: 'hubspot-meeting-booked'
          });
        }
        if (
          (event.origin === 'https://meetings.hubspot.com' ||
           event.origin === 'https://meetings-eu1.hubspot.com') &&
          event.data.meetingBookSucceeded
        ) {
          window.dataLayer.push({
            event: 'hubspot_meeting_booked'
          });
        }
      });
    `;
    document.body.appendChild(gtmScript);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const AboutBlocks = [
    {
      rootProps: { className: 'tile row' },
      image: { rootProps: { className: 'col-6 col-12-medium image' }, image: audit1 },
      textGroup: {
        rootProps: { className: 'text-group col-6 col-12-medium' },
        title: {
          type: 'h2',
          text:
            isEn ? 'Book your free hour!' : 'Marque a sua hora grátis!',
        },
        description: {
          text:
            isEn ? "EN" : "O seu site WordPress é a coluna vertebral da sua empresa! Juntos, podemos melhorar a sua presença online e converter mais visitantes em clientes.",
        },
        cta: {
            rootProps: {
              className: 'button audit-btn audit-anchor-link talktous-form',
              to: isEn ? '#' : '#',
            },
            text: isEn ? "Let's Speak" : 'Fale connosco',
        },
      },
    },
  ]

  return (
    <section id="audit-four" className="bg-blue">
      <div className="content container">
        <div className="row">
          {_.map(AboutBlocks, (block, i) => (
            <React.Fragment key={`about-block-${i}`}>
              <AboutBlock rev={i % 2} {...block} />
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="meetings-iframe-container bg-white hidden" data-src="https://meetings-eu1.hubspot.com/diogo-graca?embed=true">
      </div>
    </section>
  )
};

export default AuditFour2;
